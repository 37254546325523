import { Link } from "gatsby";
import React from "react";
import Instagram from "@images/instagram.svg";

function Header() {
  return (
    <header className="bg-darkbeige header">
      <div className="flex flex-wrap flex-row items-center justify-between md:max-w-5xl lg:max-w-6xl mx-auto px-6 py-4 md:py-8">
        <Link className="inline-block" to="/">
          <h1 className="font-bold text-xl md:text-2xl tracking-tight">
            Olle Burlin
          </h1>
        </Link>

        <nav className="flex items-center font-bold text-sm  md:text-xl">
          {[
            {
              route: `/portfolio`,
              title: `Portfolio`,
            },

            {
              route: `/kontakt`,
              title: `Kontakt`,
            },
          ].map((link) => (
            <Link
              activeClassName="text-brand"
              className="inline-block mx-2 md:mx-8 hover:text-brand"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
        <nav className="text-xl hidden md:inline-block">
          <a href="https://www.instagram.com/olleburlin">
            <div className="inline-block w-4 ml-12 mr-2">
              <img alt="Instagram" src={Instagram} />
            </div>
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
