import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import CookieConsent from "react-cookie-consent";
export default function LayoutWrapper({ children }) {
  return (
    <div>
      <Helmet>
        <meta content="SE-M" name="geo.region" />
        <meta content="Malm&ouml;" name="geo.placename" />
        <meta content="55.6;13" name="geo.position" />
        <meta content="55.6, 13" name="ICBM" />
        <meta
          name="google-site-verification"
          content="gIsBIFfqoN0KyF6G8vwj6rBrtgKl5I1ocXVxkDtt3fs"
        />
      </Helmet>
      <div className="antialiased text-grey-900">{children}</div>
      <CookieConsent
        location="bottom"
        buttonText="OK!"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#dddddd", color: "#0d0d0d" }}
        buttonStyle={{ color: "#0d0d0d", fontSize: "13px" }}
        expires={150}
      >
        Denna sidan använder Google Analytics som placerar en cookie på din
        dator{" "}
      </CookieConsent>
    </div>
  );
}
LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
